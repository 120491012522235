import { styled } from '@magal/styles'
import React from 'react'

export const StyledClickable = styled('button', {
  background: 'none',
  transition: '200ms',
  '&:focus-visible': {
    outline: '$green_09 solid 1px;',
    outlineOffset: '4px',
  },
  variants: {
    appearance: {
      outlineGreenSmall: {
        projectFont: 'caps06',
        border: '1px solid $green_09',
        color: '$green_09',
        borderRadius: '$r2',
        height: '$36',
        display: 'grid',
        gridAutoFlow: 'column',
        padding: '0 $16',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        transition: '200ms',
        '&:active': {
          opacity: '0.8',
        },
        '&:hover, &:focus-within, &:focus': {
          textDecorationColor: '$green_09',
        },
      },
      outlineGreen: {
        projectFont: 'caps06',
        border: '1px solid $green_09',
        color: '$green_09',
        borderRadius: '$r2',
        height: '$buttonHeightL',
        display: 'grid',
        gridAutoFlow: 'column',
        padding: '0 $28',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        transition: '200ms',
        textDecoration: 'underline',
        textDecorationColor: 'transparent',
        '&:active': {
          opacity: '0.8',
        },
        '&:hover, &:focus-within, &:focus': {
          textDecorationColor: '$green_09',
        },
      },
      outlineWhite: {
        projectFont: 'caps06',
        border: '1px solid $white',
        color: '$white',
        borderRadius: '$r2',
        height: '$buttonHeightL',
        display: 'grid',
        gridAutoFlow: 'column',
        padding: '0 $28',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        textDecoration: 'underline',
        textDecorationColor: 'transparent',
        '&:active': {
          opacity: '0.8',
        },
        '&:hover, &:focus-within, &:focus': {
          textDecorationColor: '$white',
        },
      },
      outlineCurrentColor: {
        projectFont: 'caps06',
        border: '1px solid currentColor',
        color: 'currentColor',
        borderRadius: '$r2',
        height: '$buttonHeightL',
        display: 'grid',
        gridAutoFlow: 'column',
        padding: '0 $28',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        textDecoration: 'underline',
        textDecorationColor: 'transparent',
        '&:active': {
          opacity: '0.8',
        },
        '&:hover, &:focus-within, &:focus': {
          textDecorationColor: 'currentColor',
        },
      },
      solidGreen: {
        projectFont: 'caps06',
        border: '1px solid $green',
        backgroundColor: '$green',
        color: '$white',
        borderRadius: '$r2',
        height: '$buttonHeightL',
        display: 'grid',
        gridAutoFlow: 'column',
        padding: '0 $28',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        textDecoration: 'underline',
        textDecorationColor: 'transparent',
        '&:active': {
          opacity: '0.8',
        },
        '&:hover, &:focus-within, &:focus': {
          textDecorationColor: '$white',
        },
      },
      solidRed: {
        textAlign: 'center',
        display: 'grid',
        gridAutoFlow: 'column',
        projectFont: 'caps06',
        borderRadius: '$r1',
        padding: '0 $24',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        border: '1px solid transparent',
        backgroundColor: '$red400',
        height: 50,
        width: '100%',
        color: '$white',
        textDecoration: 'underline',
        textDecorationColor: 'transparent',
        '&:disabled': {
          backgroundColor: '$red300',
          // color: '$gray500',
          opacity: 1,
        },
        '&:active': {
          opacity: '0.8',
        },
        '&:hover, &:focus-within, &:focus': {
          backgroundColor: '$red500',
          textDecorationColor: '$white',
        },
      },
      solidRedSmall: {
        textAlign: 'center',
        display: 'inline-block',
        gridAutoFlow: 'column',
        projectFont: 'caps06',
        fontSize: '13px',
        borderRadius: '$r1',
        padding: '0 $24',
        cursor: 'pointer',
        border: '1px solid transparent',
        backgroundColor: '$red400',
        minHeight: 30,
        width: 'auto',
        color: '$white',
        textDecoration: 'underline',
        textDecorationColor: 'transparent',
        '&:disabled': {
          backgroundColor: '$red300',
          // color: '$gray500',
          opacity: 1,
        },
        '&:active': {
          opacity: '0.8',
        },
        '&:hover, &:focus-within, &:focus': {
          backgroundColor: '$red500',
          textDecorationColor: '$white',
        },
      },
      roundOutline: {
        border: '1px solid currentColor',
        display: 'flex',
        alignItems: 'center',
      },
      textLinkLite: {
        projectFont: 'caps06',
        color: '$green_09',
        textDecoration: 'underline',
        '&:active': {
          opacity: '0.8',
        },
        '&:hover, &:focus-within, &:focus': {
          textDecorationColor: '$green_09',
        },
      },
    },
    size: {
      large: {},
      medium: {},
      small: {},
    },
  },
  compoundVariants: [],
  '&:disabled': {
    pointerEvents: 'none',
    opacity: '0.65',
  },
})

type Appearance =
  | 'outlineGreen'
  | 'outlineWhite'
  | 'outlineCurrentColor'
  | 'solidGreen'
  | 'solidRed'
  | 'solidRedSmall'
  | 'textLinkLite'
  | 'outlineGreenSmall'
  | 'roundOutline'

export type StyledClickableProps = {
  size?: 'large' | 'medium' | 'small'
  appearance?: Appearance
  tabIndex?: number
  disabled?: boolean
  role?: string
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onFocus?: () => void
  id?: string
  onClick?: (event: React.MouseEvent | React.KeyboardEvent) => void
}
