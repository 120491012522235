import { FC, useEffect, useState } from 'react'
import { styled } from '@magal/styles'

const Tooltip = styled('div', {
  background: '#F6F4F0',
  color: '#000000',
  fontSize: '12px',
  fontFamily: 'FormaDJRMicro',
  letterSpacing: '1px',
  fontWeight: '500',
  position: 'absolute',
  minWidth: '$144',
  maxWidth: '90vw',
  zIndex: 10000,
  padding: '$12 $16',
  borderRadius: '$r1 0 $r1 $r1',
  textAlign: 'left',
  transition: 'all 0.2s ease-in-out',
  opacity: 0,
  '&::after': {
    position: 'absolute',
    content: '',
    border: '7px solid #F6F4F0',
    borderTop: '7px solid transparent !important',
    borderRight: '7px solid transparent !important',
    borderLeft: '7px solid transparent !important',
    display: 'inline-block',
    width: '0',
    height: '0',
    top: '-14px',
    right: '0',
  },
  '@md': {
    maxWidth: '$378',
  },
  '& strong': {
    fontWeight: 'bold',
  },
  '& i': {
    fontStyle: 'italic',
  },
  '& span': {
    textDecoration: 'underline',
  },
})

export const ToolTip: FC<any> = ({
  show = false,
  promo,
  parentRef,
  css = {},
  calculatePos,
  children,
}) => {
  const parent = parentRef.current
  const [xPos, setXPos] = useState(0)
  const [yPos, setYPos] = useState(0)
  const [opacity, setOpacity] = useState(0)
  const [shouldShow, setShouldShow] = useState(true)

  useEffect(() => {
    if (!parent) return

    setXPos(parent.offsetLeft)
    setYPos(parent.offsetTop + 25)

    if (calculatePos) {
      setXPos(
        parent.offsetParent.offsetWidth -
          parent.offsetLeft -
          parent.offsetWidth,
      )
      setYPos(parent.offsetTop + 30)
    }

    setTimeout(() => {
      if (show) {
        setShouldShow(true)
      }

      setOpacity(show ? 1 : 0)
    }, 300)

    setTimeout(() => {
      setShouldShow(show)
    }, 600)
  }, [show, parent])

  if (!parentRef?.current || !shouldShow) return null
  return (
    <Tooltip
      css={{
        right: `${xPos}px`,
        top: `${yPos}px`,
        backgroundColor: promo?.bgColor?.hex || '#F9F6F2',
        color: promo?.textColor?.hex || '#000000',
        '&::after': {
          borderColor: promo?.bgColor?.hex || '#F9F6F2',
        },
        opacity,
        ...css,
      }}
    >
      {promo?.tooltip && (
        <div dangerouslySetInnerHTML={{ __html: promo.tooltip }}></div>
      )}

      {children}
    </Tooltip>
  )
}
