import { FC, useEffect, useState, useRef } from 'react'
import { styled } from '@magal/styles'
import {
  Media,
  ProgressBar,
  PricePrimitive,
  ToolTip,
  Button,
} from '@magal/components'
import { SiteConfiguration, AddLinesInput } from '@magal/models'
import { InfoIcon } from '@magal/icons'
import { useTranslation } from 'react-i18next'
import { getShopifyProductExtended } from '@magal/services/shopify-service'
import { useCartContext } from '../useCart'
import { useComputedCart } from '../useComputedCart'
import { GTMAddToCart } from '@magal/services/gtm-service'

const Wrapper = styled('section', {
  position: 'relative',
  display: 'flex',
  color: '$green_09',
  border: '1px solid $green_09',
  borderRadius: '3px',
  padding: '3px',
})

const Divider = styled('div', {
  padding: '4px 0',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  '@lg': {
    padding: '10px 0',
  },
})

const MediaWrap = styled('div', {
  width: 110,
  height: 110,
  minWidth: 110,
})

const Content = styled('div', {
  display: 'grid',
  width: '100%',
  margin: '0 15px',
  gridTemplateRows: 'auto auto 1fr',
})

const Title = styled('h2', {
  projectFont: 'body01',
  fontWeight: '500',
})

const Text = styled('p', {
  projectFont: 'body01',
  fontSize: '14px',
  '@lg': {
    projectFont: 'body01',
  },
})

const PriceWrapp = styled('span', {
  fontWeight: 'bold',
})

const IconWrapper = styled('div', {
  position: 'absolute',
  width: '20px',
  height: '20px',
  top: 5,
  right: 5,
  cursor: 'pointer',
})

export const RewardProduct: FC<{
  config: SiteConfiguration['cart']['cartReward']
  locale: string
}> = ({ config, locale }) => {
  const { t } = useTranslation('cart')
  const { computedCartLines } = useComputedCart()
  const { cart, addCartLines, openMiniCart } = useCartContext()
  const ToolTipRef = useRef(null)

  const [showReward, setShowReward] = useState<boolean>(false)
  const [reward, setReward] = useState<any>(undefined)
  const [progress, setProgress] = useState<number>(0)
  const [remaining, setRemaining] = useState<number>(0)
  const [showToolTip, setShowToolTip] = useState(false)

  const handleMouseOver = (state: boolean) => {
    setShowToolTip(state)
  }

  const productAlreadyInCart = (id: string) =>
    computedCartLines?.some((line) => line.merchandise.id === id)

  const addReward = async (id: string) => {
    const linesToAdd: AddLinesInput = [
      {
        merchandiseId: id,
        quantity: 1,
      },
    ]

    const addedLines = await addCartLines(linesToAdd)

    if (addedLines) {
      setShowReward(false)

      GTMAddToCart(addedLines[0], 1)
      setTimeout(() => openMiniCart(t('addedToCart')), 200)
    }
  }

  const cartAmount = cart ? parseFloat(cart.cost.totalAmount.amount) : 0

  useEffect(() => {
    if (!config.enabled) return

    if (!reward) {
      const getProduct = async () => {
        const rewardProd = await getShopifyProductExtended(
          config.handle,
          locale,
        )

        if (rewardProd.status === 'ERROR') {
          return
        }

        setReward(rewardProd.data)
      }

      getProduct()
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      if (!reward) return

      const { minimum } = config
      const userProgress = 100 - ((minimum - cartAmount) * 100) / minimum
      const id = reward?.variants[0].id
      const productInCart = productAlreadyInCart(id)

      if (productInCart) return

      setRemaining(minimum - cartAmount)
      setProgress(userProgress > 100 ? 100 : userProgress)

      if (cartAmount > minimum) {
        await addReward(id)

        return
      }

      setShowReward(true)
    })()
  }, [cartAmount, computedCartLines])

  const handleAddAnyway = async () => {
    if (!reward) return

    const id = reward?.variants[0].id
    await addReward(id)
  }

  if (reward === undefined || !showReward) return null

  return (
    <Wrapper>
      <MediaWrap>
        <Media
          mediaPayload={reward.featuredImage}
          sizes={'(min-width: 430px) 90px, 90px'}
          hardcropRatio="square"
        />
      </MediaWrap>
      <Content>
        <Title>{reward.title}</Title>
        {progress < 100 ? (
          <>
            <Text>
              Add{' '}
              <PriceWrapp>
                <PricePrimitive
                  price={{ amount: `${remaining}`, currencyCode: 'USD' }}
                />{' '}
              </PriceWrapp>
              to get this product for free
            </Text>
            <Divider>
              <ProgressBar current={progress} />
            </Divider>
            <Button appearance={'solidRedSmall'} onClick={handleAddAnyway}>
              Add Now —&nbsp;{' '}
              {<PricePrimitive price={reward.variants[0].priceV2} />}
            </Button>
          </>
        ) : (
          <Text>Enjoy your free product</Text>
        )}
      </Content>
      <IconWrapper
        onMouseEnter={() => handleMouseOver(true)}
        onMouseLeave={() => handleMouseOver(false)}
        onClick={() => handleMouseOver(!showToolTip)}
        ref={ToolTipRef}
      >
        <InfoIcon />
      </IconWrapper>

      <ToolTip
        show={showToolTip}
        parentRef={ToolTipRef}
        css={{ border: '1px solid $green_09' }}
        calculatePos
      >
        <Text>
          This booklet adds a personal touch to your gift. Inside are thoughtful
          questions to help you share memories, dreams, and special moments.
          Complete it to make your personalized jewelry an even more meaningful
          keepsake, celebrating the unique bond you share and creating a
          treasure that will be cherished for years to come.
        </Text>
      </ToolTip>
    </Wrapper>
  )
}
