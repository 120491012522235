import { FC, useEffect, useState } from 'react'
import { use100vh } from 'react-div-100vh'
import { useCartContext } from '../useCart'
import { styled } from '@magal/styles'
import { captureException } from '@sentry/nextjs'
import { useTranslation } from 'react-i18next'
import { SiteConfiguration, Upsell } from '@magal/models'
import { Container, Button, Loader, CartLine } from '@magal/components'
import { Close } from '@magal/icons'
import { GTMViewCart } from '@magal/services/gtm-service'
import { getShopifyProductExtended } from '@magal/services/shopify-service'
import { CartSummary } from './CartSummary'
import { EmptyCartLinks } from './EmptyCartLinks'
import { RewardProduct } from './RewardProduct'
import { CartUpsells } from './CartUpsells'
import router, { useRouter } from 'next/router'
import { useComputedCart } from '../useComputedCart'

const Root = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  '@md': {
    width: '$maxWidthS',
    borderLeft: '1px solid $green_09',
  },
})

const HeaderWrap = styled('div', {
  display: 'grid',
  color: '$green_09',
  '@lg': {
    paddingLeft: '$containerMarginDesktop',
    paddingRight: '$containerMarginDesktop',
  },
  variants: {
    isAbsolute: {
      true: {
        position: 'absolute',
        width: '100%',
      },
    },
  },
})

const CloseButton = styled(Button, {
  display: 'grid',
  alignItems: 'center',
  justifySelf: 'end',
  height: '$headerHeightMobile',
  padding: '0 $12',
  color: '$green_09',
  '@lg': {
    padding: '0 $16',
    marginRight: '-$24',
  },
})

const TitleWrap = styled('div', {
  display: 'grid',
  alignItems: 'start',
  gridAutoFlow: 'column',
  gap: '$24',
  paddingLeft: '$containerMarginMobile',
  '@lg': {
    padding: '$4 0',
  },
})

const CartTitle = styled('h1', {
  minHeight: '$headerHeightMobile',
  display: 'inline-grid',
  alignItems: 'center',
  padding: '$16 0',
  projectFont: 'caps02',
  color: '$green_09',
  variants: {
    large: {
      true: {
        projectFont: 'caps01',
        maxWidth: '56%',
      },
    },
  },
})

const FaceIcon = styled('span', {
  display: 'block',
  marginTop: '$56',
})

const ContentWrap = styled('div', {
  display: 'grid',
  gridTemplateRows: 'min-content',
  height: '100%',
  overflow: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
})

const ContentContainer = styled(Container, {
  display: 'grid',
})

const LoaderContainer = styled('div', {
  alignSelf: 'center',
  justifySelf: 'center',
})

const CartInfo = styled('div', {
  projectFont: 'body02',
  backgroundColor: '$green',
  padding: '$12',
  color: '$white',
  textAlign: 'center',
  marginBottom: '$12',
  '@lg': {
    marginBottom: '$12',
    marginLeft: '$containerMarginDesktop',
    marginRight: '$containerMarginDesktop',
  },
})

const LineItemsList = styled('ul', {
  display: 'grid',
  gridRowGap: '$16',
  alignContent: 'start',
  padding: '$2 0 $36 0',
})

const FooterWrap = styled('div', {
  position: 'sticky',
  bottom: 0,
  left: 0,
  borderTop: '1px solid $green_09',
  display: 'grid',
  alignContent: 'start',
  backgroundColor: '$white',
  color: '$green_09',
  paddingTop: '$containerMarginMobile',
  paddingBottom: '$containerMarginMobile',
  '@md': {
    paddingTop: '$containerMarginDesktop',
    paddingBottom: '$containerMarginDesktop',
  },
})

export const MiniCart: FC<{
  cartConfig: SiteConfiguration['cart']
  className?: string
}> = ({ cartConfig }) => {
  const { cart, closeMiniCart, miniCartState } = useCartContext()
  const { locale } = useRouter()
  const { t } = useTranslation('cart')
  const cartInfo = cartConfig?.cartInfo
  const emptyCartLinks = cartConfig?.emptyCartLinks || []
  const CartUpsellsIds = cartConfig?.cartUpsells || []
  const CartUpsellsTitle = cartConfig?.cartUpsellsTitle || ''
  const CartUpsellsEnabled: boolean = cartConfig?.cartUpsellsEnabled || false
  const cartReady = Boolean(cart)
  const isCartEmpty = !cart || cart?.totalQuantity === 0
  const [upsells, setUpsells] = useState<Upsell[]>([])

  useEffect(() => {
    if (!cart || !cart.lines) {
      return
    }

    GTMViewCart(
      cart.cost.subtotalAmount.amount,
      cart.cost.subtotalAmount.currencyCode,
      cart.lines,
    )

    const getUpsells = async () => {
      const tempUpsells = []

      for (let u = 0; u < CartUpsellsIds.length; u++) {
        const item = CartUpsellsIds[u]

        if (!item) return false

        const theItem = await getShopifyProductExtended(
          item.handle,
          locale ?? 'en',
        )

        if (theItem.status === 'ERROR') {
          const exception = new Error(
            `[[...paths]] Sanity getSiteConfiguration function error ${JSON.stringify(
              theItem.errors,
            )}`,
          )
          captureException(exception)
          throw exception
        }

        const data: Upsell = {
          item: theItem.data,
          original: item.original || null,
        }

        tempUpsells.push(data)
      }

      return setUpsells(tempUpsells)
    }

    getUpsells()
  }, [cart])

  useEffect(() => {
    const handleRouteChange = () => {
      closeMiniCart()
    }
    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events])

  const { computedCartLines, handleUpdate, handleRemove } = useComputedCart()
  const height = use100vh()
  const parsedHeight = height ?? '100vh'

  return (
    <Root
      style={{
        height: parsedHeight,
      }}
    >
      <HeaderWrap isAbsolute={isCartEmpty}>
        <TitleWrap>
          {!isCartEmpty && (
            <CartTitle>{miniCartState.title ?? t('cart')}</CartTitle>
          )}
          <CloseButton onClick={closeMiniCart}>
            <Close />
          </CloseButton>
        </TitleWrap>
      </HeaderWrap>
      <ContentWrap>
        {isCartEmpty && (
          <CartTitle large={isCartEmpty}>
            <Container>
              {t('yourCartIsEmpty')}
              <FaceIcon>:(</FaceIcon>
            </Container>
          </CartTitle>
        )}
        {!isCartEmpty && cartInfo && <CartInfo>{cartInfo}</CartInfo>}
        <ContentContainer>
          {!cartReady ? (
            <LoaderContainer>
              <Loader size={'medium'} />
            </LoaderContainer>
          ) : !isCartEmpty ? (
            <LineItemsList>
              <RewardProduct
                config={cartConfig.cartReward}
                locale={locale || 'en'}
              />
              {computedCartLines?.map((line) => {
                return (
                  <li key={line.id}>
                    <CartLine
                      line={line}
                      handleUpdateCartLine={(quantity) =>
                        handleUpdate(line, quantity)
                      }
                      handleRemoveCartLine={() => handleRemove(line)}
                    />
                  </li>
                )
              })}
            </LineItemsList>
          ) : (
            <EmptyCartLinks emptyCartLinks={emptyCartLinks} />
          )}
        </ContentContainer>
      </ContentWrap>
      {!isCartEmpty && (
        <FooterWrap>
          <CartUpsells
            CartUpsellsEnabled={CartUpsellsEnabled}
            cartUpsells={upsells}
            CartUpsellsTitle={CartUpsellsTitle}
          ></CartUpsells>
          <Container>
            <CartSummary
              freeDeliveryMinimumAmount={cartConfig.freeDeliveryMinimumAmount}
            />
          </Container>
        </FooterWrap>
      )}
    </Root>
  )
}
