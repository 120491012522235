import { FC, useEffect, useState } from 'react'
import { styled } from '@magal/styles'

const Wrapper = styled('div', {
  position: 'relative',
  border: '2px solid $green_09',
  borderRadius: '20px',
  width: '100%',
})

const Progress = styled('h2', {
  width: '100%',
  height: '5px',
  background: 'gray',
  borderRadius: '20px',
  transition: 'all 0.5s ease-in-out',
  backgroundImage:
    'repeating-linear-gradient(130deg, transparent, transparent 8px, $green_09 8px, $green_09 17px )',
})

export const ProgressBar: FC<{
  current: number
}> = ({ current = 0 }) => {
  const [progress, setProgress] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => setProgress(current), 350)
  }, [])

  return (
    <Wrapper>
      <Progress style={{ width: `${progress}%` }} />
    </Wrapper>
  )
}
